import { ofetch } from 'ofetch'

const fetch = ofetch.create({
  headers: {
    'accept-language': document.documentElement.lang,
  },
})

/**
 * Check if the given code is "alumni" valid
 * @param code - The code to check
 * @returns Whether the code is valid
 */
export function checkAlumni(code: string) {
  return fetch<{ success: boolean }>(
    '/wp-json/crea-registrations/check-alumni',
    { method: 'POST', query: { code } },
  )
}
export interface GetSchoolLevel2Response {
  success: true
  data: Record<string, string>
}

export interface GetSchoolLevel2Error {
  success: false
  data: Record<string, Record<string, string>>
}

/**
 * Get the school level 2 of the given school level
 * @param schoolLevel - The school level
 * @returns The school level 2
 */
export function getSchoolLevel2(schoolLevel: string) {
  return fetch<GetSchoolLevel2Response>(
    '/wp-json/crea-registrations/get-school-level2',
    { query: { school_level: schoolLevel } },
  )
}

export interface SubmitRegistrationResponse {
  success: true
  data: {
    id: string
  }
}

export interface SubmitRegistrationError {
  success: false
  data: Record<string, Record<string, string>>
}

/**
 * Send the candidate's registration form data to the server
 * @param body - The form data of the candidate
 * @param isAlumni - Whether the candidate is an alumni
 * @returns The response from the server
 */
export function submitRegistration(
  body: Record<string, string | string[]>,
  isAlumni?: boolean,
) {
  return fetch<SubmitRegistrationResponse>(
    '/wp-json/crea-registrations/submit',
    { method: 'POST', body, query: { is_alumni: isAlumni ? '1' : '0' } },
  )
}
